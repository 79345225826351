import React from 'react';

import cx from 'classnames';
import useDeviceDetect from 'contexts/UseDeviceDetect/UseDeviceDetect';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { SortOptionButtons } from 'components/SortItems/SortOptionButtons';

export interface SortOptionListItemProps {
  handleDragStart: (item: { id: number; position: number }) => void;
  handleDragover: (e) => void;
  handleDrop: (e, position: number) => void;
  id: number;
  label: string;
  matchItems: Record<string, any>[];
  onChange?: (value: number, id: number, type: string) => void;
  onClick: (oldPosition: number, newPosition: number) => void;
  position: number;
  selected?: number;
}

function SortOptionListItem({
  id,
  label,
  matchItems,
  position,
  handleDragStart,
  handleDragover,
  handleDrop,
  onClick,
  onChange,
  selected,
}: SortOptionListItemProps) {
  const { isMobile } = useWindowSize();
  const { isTouchDevice } = useDeviceDetect();

  return (
    <li
      className="ek-option-list__item"
      onDrop={!isTouchDevice ? (e) => handleDrop(e, position) : null}
      onDragOver={!isTouchDevice ? (e) => handleDragover(e) : null}
    >
      <div
        className={cx('ek-option-item', { 'is-mobile-device': isMobile })}
        draggable={!isTouchDevice}
        onDragStart={!isTouchDevice ? () => handleDragStart({ id, position }) : null}
      >
        <div
          className={cx('ek-option-item__action-container', {
            'is-tablet-device': !isMobile && isTouchDevice,
          })}
        >
          <SortOptionButtons
            id={id}
            items={matchItems}
            onClick={onClick}
            onChange={onChange}
            position={position}
            selected={selected}
            maxPosition={matchItems.length - 1}
          />
        </div>
        <div className="ek-option-item__details">
          <p className="ek-option-item__title">{label}</p>
        </div>
      </div>
    </li>
  );
}

export { SortOptionListItem };
