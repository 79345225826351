import React from 'react';

import { SortMatchListItem } from 'components/SortItems/SortMatchListItem';

export interface SortMatchListProps {
  matchItems: Record<string, any>[];
  onChange?: (value: number, id: number, type: string) => void;
  options: Record<string, any>[];
}

function SortMatchList({ options, matchItems, onChange }: SortMatchListProps) {
  const getSelected = (item) => {
    const selected = options.find((match) => match.questionAnswerMatchId === item.id);
    return selected ? selected.questionResponseMatchId : null;
  };
  return (
    <ol className="ek-match-list">
      {matchItems.map((item) => (
        <SortMatchListItem
          key={`match_${item.id}`}
          id={item.id}
          label={item.text}
          options={options}
          onChange={onChange}
          item={item}
          selected={getSelected(item)}
        />
      ))}
    </ol>
  );
}

export { SortMatchList };
