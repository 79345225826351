/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import cx from 'classnames';

import { LayoutProps } from './Layout.types';

const typeMap = Object.freeze({
  1: 'even-split',
  2: 'even-split',
  3: 'wide-start',
  4: 'wide-end',
  5: 'slim-start',
  6: 'slim-end',
  7: 'even-split',
  8: 'fat-start',
  9: 'fat-end',
  10: 'even-split',
});

/**
 * Primary UI component for Layout inside a Container
 */
const Layout: React.FC<LayoutProps> = ({ as = 'div', children, gap = 'none', typeId }) => {
  /* `as` is already typed */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = as;
  return (
    <Tag
      className={cx({
        'l-layout': true,
        [`l-layout--${typeMap[typeId]}`]: typeId,
        [`l-layout--gap-${gap}`]: gap,
      })}
    >
      {children}
    </Tag>
  );
};

export { Layout };
