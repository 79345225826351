import { Snippet } from 'components/Shared/Ekardo/Snippet';

function sortFunc(a, b) {
  return a.sort - b.sort;
}

export function FormSnippet({ form, validationMessages }) {
  const sections = form.contentPages[0].contentSections;

  return (
    <>
      {sections &&
        [...sections].sort(sortFunc).map(({ id: sectionId, sectionContainers }) => {
          return (
            <form key={sectionId} className="l-flex__item--column l-full-width l-flex l-full-width h-background--color-white">
              {[...sectionContainers].sort(sortFunc).map(({ id: containerId, snippets }) => {
                return (
                  <span key={containerId} className="l-flex__item--row l-flex l-flex--column l-full-width">
                    {[...snippets].sort(sortFunc).map((snippet) => {
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      return (
                        <div key={snippet.id}>
                          <Snippet key={snippet.id} {...snippet} />
                          {validationMessages?.[snippet.id] && (
                            <div className="h-border h-border--color-brand-tertiary h-color--brand-tertiary h-font-weight--600 h-with-y-margin h-with-padding">
                              {validationMessages[snippet.id]}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </span>
                );
              })}
            </form>
          );
        })}
    </>
  );
}
