import React from 'react';

import cx from 'classnames';

import { RadioButton } from './RadioButton';

export interface RadioGroupProps {
  error?: string;
  isLabelHidden?: boolean;
  items: Record<string, any>[];
  label: string;
  name: string;
  onChange?: (value, id) => void;
  selected?: number;
}

export function RadioGroup({ label, isLabelHidden, name, selected, items, error, onChange }: RadioGroupProps) {
  return (
    <fieldset aria-describedby={error ? `id_${name}_error` : null}>
      <legend
        className={cx({
          'ek-input-field__label': true,
          'h-sr-only': isLabelHidden,
        })}
      >
        {label}
      </legend>
      <div className="l-flex l-flex--mobile-column">
        {items.map((item) => (
          <RadioButton
            key={item.id}
            onChange={onChange}
            name={name}
            id={`id_${item.id}_${name}`}
            value={item.value ? item.value : item.id}
            labelText={item.text}
            isChecked={(item.value ? item.value : Number(item.id)) === selected}
          />
        ))}
      </div>
      {error ? (
        <p id={`id_${name}_error`} className="ek-error-message">
          {error}
        </p>
      ) : null}
    </fieldset>
  );
}
