import { useEkardoPageData } from 'utils/hooks/useEkardoPageData';

import { LoadingIcon } from 'components/Spinners/LoadingIcon';
import { LoadingOverlay } from 'components/Spinners/LoadingOverlay';

import { EkardoContentPage } from './EkardoContentPage';

export interface EkardoPageProps {
  className?: string;
  hideLoader?: boolean;
  isInsideContainer?: boolean;
  page?: any;
  pageId?: number;
  pageLookup?: string;
  pageUrl?: string;
  smallLoader?: boolean;
  transparentBackground?: boolean;
  userFlowStepTrackId?: number;
  validationMessages?: Record<number, string>;
}

export function EkardoPage({
  hideLoader,
  className,
  isInsideContainer,
  page,
  validationMessages,
  transparentBackground,
  pageLookup,
  pageUrl,
  pageId,
  userFlowStepTrackId,

  smallLoader,
}: EkardoPageProps) {
  const { page: sections, isPageLoading } = useEkardoPageData({
    contentPageName: pageLookup,
    contentPageUrl: pageUrl,
    userFlowStepTrackId,
    contentPageId: pageId,
  });
  return (
    <>
      {!isPageLoading &&
        sections.map(({ id: sectionId, sectionContainers, contentLayoutId, backgroundColor: sectionBackgroundColor }, index) => {
          return (
            <EkardoContentPage
              className={className}
              contentLayoutId={contentLayoutId}
              index={index}
              isInsideContainer={isInsideContainer}
              sectionBackgroundColor={sectionBackgroundColor}
              sectionContainers={sectionContainers}
              sectionId={sectionId}
              transparentBackground={transparentBackground}
              validationMessages={validationMessages}
            />
          );
        })}
      {isPageLoading && !smallLoader && !hideLoader && <LoadingOverlay />}
      {isPageLoading && smallLoader && !hideLoader && <LoadingIcon />}
    </>
  );
}
