import React, { useEffect, useState } from 'react';

import { useWindowSize } from 'utils/hooks/useWindowSize';

import { SortMatchList } from 'components/SortItems/SortMatchList';
import { SortOptionList } from 'components/SortItems/SortOptionList';

export interface SortItemsProps {
  isSummary: boolean;
  matchItems: Record<string, any>[];
  onDrop: (arg: any) => void;
  options: Record<string, any>[];
  selectedOptions?: Record<string, any>[];
}
function shuffleArray(array) {
  const temp = [...array];
  for (let i = temp.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [temp[i], temp[j]] = [temp[j], temp[i]];
  }
  return temp;
}

function SortItems({ matchItems, options, selectedOptions = [], isSummary, onDrop }: SortItemsProps) {
  const { isMobile } = useWindowSize();
  const [draggedItem, setDraggedItem] = useState(null);
  const [responseItems, setResponseItems] = useState([]);

  const matchOptions = [...matchItems].sort((a, b) => a.sort - b.sort);

  useEffect(() => {
    if (selectedOptions.length === 0) {
      setResponseItems(
        shuffleArray(options).map((option, index) => {
          return {
            ...option,
            definedPosition: index,
            questionResponseMatchId: option.id,
            questionAnswerMatchId: matchItems.find((matchItem) => {
              return matchItem.sort === index;
            }).id,
          };
        }),
      );
    } else {
      setResponseItems(
        [...selectedOptions]
          .sort((a, b) => a.definedPosition - b.definedPosition)
          .map((selected) => {
            return {
              ...options.find((option) => selected.questionResponseMatchId === option.id),
              ...selected,
            };
          }),
      );
    }
  }, []);

  useEffect(() => {
    if (responseItems.length > 0) onDrop(responseItems);
  }, [responseItems]);

  const chooseOption = (oldPosition: number, newPosition: number) => {
    const tempItems = [...responseItems];

    const oldId = tempItems[oldPosition].questionAnswerMatchId;
    const newId = tempItems[newPosition].questionAnswerMatchId;

    tempItems[newPosition].definedPosition = oldPosition;
    tempItems[newPosition].questionAnswerMatchId = oldId;

    tempItems[oldPosition].definedPosition = newPosition;
    tempItems[oldPosition].questionAnswerMatchId = newId;

    tempItems.sort((a, b) => a.definedPosition - b.definedPosition);
    setResponseItems(tempItems);
  };

  const handleChange = (value: number, id: number, type: string) => {
    const newPosition = responseItems.find(
      (match) => match[type === 'match' ? 'questionResponseMatchId' : 'questionAnswerMatchId'] === value,
    );
    const oldPosition = responseItems.find((match) => match[type === 'match' ? 'questionAnswerMatchId' : 'questionResponseMatchId'] === id);
    chooseOption(oldPosition.definedPosition, newPosition.definedPosition);
  };

  const drop = (e, position: number) => {
    if (isSummary) return;
    e.preventDefault();
    chooseOption(draggedItem.position, position);
    setDraggedItem(null);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="ek-sort-container">
      <ol className={`ek-sort-list ${isMobile ? 'ek-sort-list--mobile' : ''}`}>
        <li className="ek-sort__item">
          <SortMatchList options={responseItems} matchItems={matchOptions} onChange={handleChange} />
        </li>
        <li className="ek-sort__item">
          <SortOptionList
            options={responseItems}
            matchItems={matchOptions}
            handleDragStart={setDraggedItem}
            handleDragover={dragOver}
            handleDrop={drop}
            onClick={chooseOption}
            onChange={handleChange}
          />
        </li>
      </ol>
    </div>
  );
}

export { SortItems };
