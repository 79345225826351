/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { useAccessToken, useGroup, usePermissions, useUser } from 'contexts/Authorization/Context';
import { TrackingProvider } from 'contexts/Tracking/Context';
import { Client } from 'graphql/client';
import router, { useRouter } from 'next/router';
import { getRole } from 'utils/functions/getRoleType';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useQuery } from 'utils/hooks/useQuery';

import { Button, ButtonLink } from 'components/Shared/Inputs/Button';
import { EkardoPage } from 'components/Shared/Ekardo/EkardoPage';
import { LoadingOverlay } from 'components/Spinners/LoadingOverlay';
import { ProgressList } from 'components/Shared/UI/ProgressList';
import { useCurrentUserFlowForProject } from 'utils/hooks/useCurrentUserFlowForProject';
import { getModuleCompletions } from 'utils/functions/getModuleCompletions';
import { useMutation } from 'utils/hooks/useMutation';

const GetUnitsQuery = gql`
  query GetUnitsQuery($unitId: Int!) {
    unit {
      get(unitIds: [$unitId]) {
        contentGroup {
          groupingContentGroup {
            id
          }
          id
        }
        mode
      }
    }
  }
`;

const GetUserFlowStepQuery = gql`
  query GetUserFlowStepQuery($flowId: Int!, $unitId: Int, $stepUrl: String!, $isTeacher: Boolean!) {
    unit @skip(if: $isTeacher) {
      get(unitIds: [$unitId]) {
        contentGroup {
          id
        }
        mode
      }
    }
    userFlow {
      getUserFlow(userFlowId: $flowId) {
        id
        title
      }
    }
    userFlowStep {
      get: getUserFlowStepByName(userFlowStepUrl: $stepUrl, userFlowId: $flowId) {
        id
        contentGroup {
          id
          contentPages(status: [PUBLISHED, UNPUBLISHED]) {
            id
            mode
            sort
            url
          }
        }
      }
    }
  }
`;

const GetFlowInformation = gql`
  query getUserFlowByUrl($url: String!, $projectId: String!) {
    userFlow {
      getUserFlowByUrl(projectId: $projectId, url: $url) {
        id
        title
        url
      }
    }
  }
`;

const SEND_INTERNAL_NOTIFICATIONS = gql`
  mutation ($projectId: String!, $metadata: String!, $placeholders: [PlaceholdersInputType], $templateId: Int!, $userId: Int!) {
    notification {
      sendInternalNotifications(
        notificationGroup: {
          subject: "Module completed"
          projectId: $projectId
          templateId: $templateId
          notifications: { metadata: $metadata, userId: $userId, placeholders: $placeholders }
        }
      )
    }
  }
`;

const GetQuestionAnswersQuery = gql`
  query GetQuestionAnswersQuery($stepTrackingId: Int!) {
    questionAnswer {
      getAnswers(trackingId: $stepTrackingId) {
        id
        contentSnippetQuestionId
        ... on QuestionAnswerCheckboxType {
          selectedAnswers {
            questionResponse {
              id
              value: scoreValue
            }
          }
          __typename
        }
        ... on QuestionAnswerDropDownListType {
          questionResponse {
            id
            value: scoreValue
          }
          __typename
        }
        ... on QuestionAnswerMultiResponseTextType {
          id
          responses {
            value
            __typename
          }
          __typename
        }
        ... on QuestionAnswerNumberType {
          value: numberAnswered
          __typename
        }
        ... on QuestionAnswerRadioType {
          questionResponse {
            id
            value: scoreValue
          }
          __typename
        }
        ... on QuestionAnswerSliderType {
          value
          __typename
        }
      }
    }
  }
`;

const ValidatePageQuery = gql`
  query ValidatePage($contentPageId: Int!, $trackingId: Int!) {
    questionAnswer {
      validatePage(contentPageId: $contentPageId, trackingId: $trackingId) {
        message
        question {
          id
        }
      }
    }
  }
`;

interface IActivityContext {
  questionAnswers?: Record<number, any>;
  setQuestionAnswer: (questionId: number, answer: any) => void;
}

const Context = createContext<IActivityContext>({
  questionAnswers: [],
  setQuestionAnswer: () => {},
});

function AnswersProvider({ children, stepTrackingId, client }) {
  const [questionAnswers, setQuestionAnswers] = useState<Record<number, any>>({});

  function setQuestionAnswer(questionId, answer) {
    setQuestionAnswers((currentState) => {
      const temp = { ...currentState };
      temp[questionId] = answer;
      return temp;
    });
  }

  useQuery(GetQuestionAnswersQuery, {
    client,
    fetchPolicy: 'cache-and-network',
    variables: {
      stepTrackingId,
    },
    onCompleted(response) {
      const {
        questionAnswer: { getAnswers: answers },
      } = response;

      const updatedQuestionAnswers = answers.reduce((accumulator, answer) => {
        const { contentSnippetQuestionId, questionResponse, selectedAnswers, __typename: typeName, value } = answer;

        let questionAnswer;

        switch (typeName) {
          case 'QuestionAnswerCheckboxType': {
            questionAnswer = selectedAnswers.reduce((memo, current) => Number(memo) + Number(current.questionResponse.value), 0);
            break;
          }
          case 'QuestionAnswerDropDownListType': {
            questionAnswer = questionResponse.value;
            break;
          }
          case 'QuestionAnswerMultiResponseTextType': {
            questionAnswer = answer.responses.map((answerResponse) => answerResponse.value);
            break;
          }
          case 'QuestionAnswerNumberType': {
            questionAnswer = value;
            break;
          }
          case 'QuestionAnswerRadioType': {
            questionAnswer = questionResponse.value;
            break;
          }
          case 'QuestionAnswerSliderType': {
            questionAnswer = value;
            break;
          }
          default: {
            questionAnswer = 0;
          }
        }

        return {
          ...accumulator,
          [contentSnippetQuestionId]: questionAnswer,
        };
      }, {});

      setQuestionAnswers(updatedQuestionAnswers);
    },
  });

  return (
    <Context.Provider
      value={{
        questionAnswers,
        setQuestionAnswer,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useQuestionAnswers() {
  return useContext(Context).questionAnswers;
}

export function useSetQuestionAnswer() {
  return useContext(Context).setQuestionAnswer;
}

export function ActivityContent() {
  const group = useGroup();
  const roleType = getRole(usePermissions(), group.id);
  const projectId = process.env.REACT_APP_PROJECT_ID;
  const client = useRef(Client('ekardo'));
  const user = useUser();
  const howlerClient = useRef(Client('howler'));

  const {
    push,
    query: { moduleUrl, pageUrl, stepUrl },
  } = useRouter();

  const [executeSendNotifications] = useMutation(SEND_INTERNAL_NOTIFICATIONS, {
    client: howlerClient.current,
  });

  const [completeContentPageTrackingFunction, setCompleteContentPageTrackingFunction] = useState(undefined);
  const [completeStepTrackingFunction, setCompleteStepTrackingFunction] = useState(undefined);
  const [pages, setPages] = useState([]);
  const [userFlow, setUserFlow] = useState(undefined);
  const [validationMessages, setValidationMessages] = useState<any>([]);
  const [flowId, setFlowId] = useState(undefined);
  const [executeGetFlowInformationQuery] = useLazyQuery(GetFlowInformation, {
    client: client.current,
    onCompleted: (data) => {
      const {
        userFlow: {
          getUserFlowByUrl: { id },
        },
      } = data;

      setFlowId(id);
    },
  });

  const [getUnit, { data: dataUnit }] = useLazyQuery(GetUnitsQuery);

  useEffect(() => {
    if (!user.unit) return;
    getUnit({
      variables: {
        unitId: user.unit.id,
      },
    });
  }, []);

  const [executeGetUserFlowStep, { loading: isGetUserFlowStepLoading }] = useLazyQuery(GetUserFlowStepQuery, {
    client: client.current,
    onCompleted(response) {
      handleGetUserFlow(response);
    },
  });

  const onPageCompletedWhenTeacher = (completeStepTracking, completeContentPageTracking) => {
    const url = nextPage ? `/${preRoute}/${moduleUrl}/${stepUrl}/activity/${nextPage.url}` : `/${preRoute}/${moduleUrl}`;

    if (!nextPage) {
      completeStepTracking();
    }

    completeContentPageTracking({
      isLastPage: !nextPage,
      onCompleted() {
        push(url);
      },
    });
  };

  const onExecuteValidatePageCompleted = (response) => {
    const questionValidations = response?.questionAnswer?.validatePage;

    if (!questionValidations?.length) {
      const url = nextPage ? `/${preRoute}/${moduleUrl}/${stepUrl}/activity/${nextPage.url}` : `/${preRoute}/${moduleUrl}`;

      if (!nextPage) {
        completeStepTrackingFunction();
      }

      handleValidatePage(nextPage, url);
      return;
    }

    const validationMessages = questionValidations.reduce((accumulator, questionValidation) => {
      const {
        message,
        question: { id: questionId },
      } = questionValidation;

      return {
        ...accumulator,
        [questionId]: message,
      };
    }, {});

    setValidationMessages(validationMessages);
  };

  const [executeValidatePage, { loading: isValidatePageLoading }] = useLazyQuery(ValidatePageQuery, {
    client: client.current,
    fetchPolicy: 'cache-and-network',
    onCompleted: onExecuteValidatePageCompleted,
  });

  function handleGetUserFlow(response) {
    const {
      userFlow: { getUserFlow: userFlow },
      userFlowStep: { get: userFlowStep },
    } = response;

    const { unit } = response;

    const { get: units } = unit ?? [];
    const {
      contentGroup: { contentPages },
    } = userFlowStep;

    const { mode = {} || {} } =
      units?.find((unit) => {
        const {
          contentGroup: { id: unitContentGroupId },
        } = unit;

        const {
          contentGroup: { id: userFlowStepContentGroupId },
        } = userFlowStep;

        return unitContentGroupId === userFlowStepContentGroupId;
      }) ?? [];

    const pages = [...contentPages]
      .filter((contentPage) => {
        return roleType === 'Teacher' || contentPage.mode.includes(mode);
      })
      .sort((a, b) => a.sort - b.sort);

    setPages(pages);
    setUserFlow(userFlow);
  }

  function handleSubmit(contentPageId, completeContentPageTrackingFunction, trackingId, completeUserFlowStepTracking) {
    setCompleteContentPageTrackingFunction(() => completeContentPageTrackingFunction);
    setCompleteStepTrackingFunction(() => completeUserFlowStepTracking);

    if (roleType === 'Teacher') {
      onPageCompletedWhenTeacher(completeUserFlowStepTracking, completeContentPageTrackingFunction);
    } else {
      executeValidatePage({
        variables: {
          contentPageId,
          trackingId,
        },
      });
    }
  }

  function handleValidatePage(nextPage, url) {
    completeContentPageTrackingFunction({
      isLastPage: !nextPage,
      onCompleted() {
        push(url);
      },
    });
  }

  useEffect(() => {
    if (!projectId || !moduleUrl) {
      return;
    }

    executeGetFlowInformationQuery({
      variables: {
        projectId,
        url: moduleUrl,
      },
    });
  }, [projectId, moduleUrl]);

  useEffect(() => {
    if (!flowId || !stepUrl || !user) {
      return;
    }

    executeGetUserFlowStep({
      variables: {
        flowId,
        isTeacher: roleType === 'Teacher',
        stepUrl,
        unitId: user.unit?.id,
      },
    });
  }, [flowId, stepUrl, user]);

  useEffect(() => {
    if (!pages || pages.length === 0) return;
    const currentPage = pages.find((page) => page.url === pageUrl);
    if (!currentPage) router.push(`/${preRoute}/${moduleUrl}/${stepUrl}/activity/${pages[0].url}`);
  }, [pages]);

  const currentPage = pages.find((page) => page.url === pageUrl);
  const currentPageIndex = pages.findIndex((page) => page.url === pageUrl);
  const isFirstPage = currentPageIndex === 0;
  const nextPage = pages[currentPageIndex + 1];
  const preRoute = roleType === 'Teacher' ? 'teacher/modules' : 'student';

  const buttonText = currentPageIndex > 0 || !nextPage ? 'Submit' : 'Begin';

  const isLoading = isGetUserFlowStepLoading || isValidatePageLoading || !userFlow;
  const userFlowTitle = userFlow ? userFlow.title : '';

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <TrackingProvider>
        {({
          completeContentPageTracking,
          pageTrackingId,
          stepTrackingId,
          completeUserFlowStepTracking,
          isSubmitAvailable,
          isCompleted,
        }) => {
          const mustPerformedGetUserFlow = Boolean(!nextPage) && Boolean(stepTrackingId) && !isCompleted;
          const { selectedUserFlow: selectedModule } = useCurrentUserFlowForProject(mustPerformedGetUserFlow, false);
          if (selectedModule) {
            const lessons = getModuleCompletions(selectedModule, dataUnit?.unit);
            if (lessons.every((r) => r.userStatus === 'COMPLETED'))
              executeSendNotifications({
                variables: {
                  projectId: process.env.REACT_APP_PROJECT_ID,
                  templateId: process.env.REACT_APP_TEMPLATE_MODULE_COMPLETED,
                  metadata: `{\"title\":\"${selectedModule.title}\",\"type\":\"ModuleCompleted\"}`,
                  userId: user.id,
                  placeholders: [{ key: '[UserFlowName]', value: selectedModule.title }],
                },
              });
          }

          return (
            <>
              <section className="l-full-width h-background--color-white">
                <div className="l-container l-container--medium">
                  <h1 className="c-typography--special">{userFlowTitle}</h1>
                </div>
              </section>
              <section className="l-full-width h-background--color-grey-300">
                <div className="l-container l-container--medium">
                  <ProgressList currentStepIndex={currentPageIndex} steps={pages.map(() => '')} />
                </div>
              </section>
              {pageTrackingId && (
                <AnswersProvider stepTrackingId={stepTrackingId} client={client.current}>
                  <EkardoPage
                    className="c-student-activity-content"
                    validationMessages={validationMessages}
                    pageUrl={pageUrl as string}
                    userFlowStepTrackId={stepTrackingId}
                  />
                </AnswersProvider>
              )}
              <section className="l-full-width h-background--color-white">
                <div className="l-container l-container--medium">
                  <div className="l-flex l-flex--justify-end" style={{ marginRight: '1.5rem' }}>
                    {moduleUrl && !isFirstPage && (
                      <ButtonLink
                        className="l-flex__item--row"
                        icon="arrow"
                        rotateIcon="90"
                        iconPosition="left"
                        href={`/${preRoute}/${moduleUrl}`}
                      >
                        Save & return later
                      </ButtonLink>
                    )}

                    <Button
                      className="l-flex__item--row"
                      isDisabled={!pageTrackingId || isSubmitAvailable}
                      isLoading={isSubmitAvailable}
                      icon="arrow"
                      rotateIcon="270"
                      onClick={() =>
                        handleSubmit(currentPage.id, completeContentPageTracking, stepTrackingId, completeUserFlowStepTracking)
                      }
                    >
                      {!isSubmitAvailable ? buttonText : <>Saving responses</>}
                    </Button>
                  </div>
                </div>
              </section>
            </>
          );
        }}
      </TrackingProvider>
    </>
  );
}
