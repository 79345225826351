import stringMath from 'string-math';

import { useQuestionAnswers } from 'components/ActivityContent/ActivityContent';

export function TextSnippet({ text }) {
  const answers = useQuestionAnswers();

  const manipulated = text
    .replace(/\{\{Question.([0-9]*)\}\}/g, (_replace, group) => {
      return answers[group] || 0;
    })
    .replace(/\{\{Calculate.\((.*?)\)\}\}/gm, (_replace, group) => {
      try {
        return stringMath(group).toFixed(0);
      } catch {
        return 0;
      }
    });
  return <div className="l-flex__item--column ek-text-snippet" dangerouslySetInnerHTML={{ __html: manipulated }} />;
}
