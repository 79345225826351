import { useEffect, useState } from 'react';

import dompurify from 'dompurify';

export function CodeSnippet({ asset: { presignedUrl } }) {
  const [htmlContent, setHtmlContent] = useState(null);
  const { sanitize } = dompurify;

  useEffect(() => {
    if (!htmlContent) {
      const Http = new XMLHttpRequest();
      Http.open('GET', presignedUrl);
      Http.onload = () => {
        if (Http.status >= 200 && Http.status <= 299) {
          setHtmlContent(Http.responseText);
        }
      };
      Http.send();
    }
  });

  return (
    <div
      className="l-flex__item--column"
      dangerouslySetInnerHTML={{
        __html: sanitize(htmlContent, { FORCE_BODY: true }),
      }}
    />
  );
}
