import React from 'react';

import cx from 'classnames';
import useDeviceDetect from 'contexts/UseDeviceDetect/UseDeviceDetect';
import { useMediaQuery } from 'react-responsive';

import { DragItemMobileControls } from 'components/Shared/Inputs/DragAndDrop/DragItemMobileControls';
import { Icon } from 'components/Shared/UI/Icon';

export interface DragItemProps {
  handleChoose?: (value, id) => void;
  handleDragStart?: (id) => void;
  id?: string;
  isMatched?: boolean;
  label: string;
  matchOptions?: Record<string, any>[];
  matchedItems?: Record<string, any>[];
  showHide?: (e) => void;
}

declare const window: any;
declare const safari: any;

export function DragItem({
  isMatched = false,
  matchOptions,
  handleDragStart,
  handleChoose,
  showHide,
  label,
  id,
  matchedItems = [],
}: DragItemProps) {
  const { isTouchDevice } = useDeviceDetect();

  const isSafari = !!window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

  const isSmallOrMediumDevice = useMediaQuery({ query: '(max-width: 1024px)' });
  const isSmallerDeviceSafari = isSafari && isSmallOrMediumDevice;

  const getSelectedOption = () => {
    const selected = matchedItems.find((match) => match.questionResponseMatchId === id);
    return selected ? selected.questionAnswerMatchId : 0;
  };

  return (
    <li className="ek-drag-list__item">
      <div
        className={cx({
          'ek-drag-item': true,
          'is-touch-device': isTouchDevice || isSmallerDeviceSafari,
          'is-active': (isTouchDevice || isSmallerDeviceSafari) && isMatched,
        })}
        draggable={!isTouchDevice || !isSmallerDeviceSafari}
        onDragStart={!isTouchDevice || !isSmallerDeviceSafari ? () => handleDragStart(id) : null}
      >
        {!isTouchDevice ? <Icon className="ek-drag-item__icon" id="three_dots" size="md" color="primary" /> : null}
        <h3
          className={cx({
            'ek-drag-item__title': true,
            'is-touch-device': isTouchDevice || isSmallerDeviceSafari,
          })}
        >
          {label}
        </h3>
        {isTouchDevice || isSmallerDeviceSafari ? (
          <DragItemMobileControls
            handleChoose={handleChoose}
            showHide={showHide}
            id={id}
            matchOptions={matchOptions}
            selectedOption={getSelectedOption()}
          />
        ) : null}
      </div>
    </li>
  );
}
