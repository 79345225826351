import React from 'react';

import cx from 'classnames';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { SortItemSelect } from 'components/SortItems/SortItemSelect';

export interface SortMatchListItemProps {
  id: number;
  item: Record<string, any>;
  label: string;
  onChange?: (value: number, id: number, type: string) => void;
  options: Record<string, any>[];
  selected: number;
}

function SortMatchListItem({ id, label, options, onChange, selected, item }: SortMatchListItemProps) {
  const { isMobile } = useWindowSize();
  return (
    <li className={cx('ek-match-list__item', { 'is-mobile-device': isMobile })}>
      <div className={cx('ek-match-item', { 'is-mobile-device': isMobile })}>
        {isMobile ? (
          <div className="ek-match-item__action-container">
            <SortItemSelect id={id} items={options} type="match" selected={selected} onChange={onChange} />
          </div>
        ) : null}
        <div className="ek-match-item__details">
          {item.asset && <img className="ek-match-item__icon" src={item.asset.presignedUrl} alt={item.asset.alt || ''} />}

          <p className="ek-match-item__title">{label}</p>
        </div>
      </div>
    </li>
  );
}

export { SortMatchListItem };
