import { useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import Slider from 'rc-slider';
import { useMutation } from 'utils/hooks/useMutation';

import { useSetQuestionAnswer } from 'components/ActivityContent/ActivityContent';

const AnswerSliderInput = gql`
  mutation AnswerMultiTextInput($value: Int!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerSlider: {
          value: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;
export function SliderInput({ id, question, configuration, answers }) {
  const [value, setValue] = useState(answers?.value ?? configuration.defaultValue ?? '');
  const marks = {};

  configuration.ticks.forEach(({ sliderValue, label }) => {
    marks[sliderValue] = { label };
  });

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerSliderInput, { onCompleted: () => setIsLoading({ isLoading: false }) });
  const setAnswer = useSetQuestionAnswer();

  function onBlur() {
    setAnswer(id, Number(value));
    setIsLoading({ isLoading: true });
    sendAnswer({
      variables: {
        value,
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }

  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      <span className="l-flex__item--column l-flex l-flex--justify-space-between">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className="ek-question-snippet c-typography--bold c-typography--color-brand"
          dangerouslySetInnerHTML={{ __html: question }}
          htmlFor={`ek-question-${id}`}
        />
        <span className="c-typography--intro c-typography--bold">{value}</span>
      </span>
      <Slider
        className="c-input__slider l-flex__item--column"
        value={value}
        onChange={setValue}
        min={configuration.minValue}
        max={configuration.maxValue}
        step={configuration.step}
        marks={marks}
        onAfterChange={onBlur}
      />
    </div>
  );
}
