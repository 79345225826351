import { useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

import { useSetQuestionAnswer } from 'components/ActivityContent/ActivityContent';

const AnswerDropDownInput = gql`
  mutation AnswerDropDownInput($value: Int!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerDropDownList: {
          questionResponseDropDownListId: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

function InlineInput({ questionId, questionFragments, value, availableResponses, handleChange, isSummary }) {
  return (
    <>
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column l-flex"
        htmlFor={`ek-question-${questionId}`}
      >
        <span className="l-flex__item--row" dangerouslySetInnerHTML={{ __html: questionFragments[0] }} />{' '}
        <select
          disabled={isSummary}
          id={`ek-question-${questionId}`}
          className="h-border h-border--color-grey-700 l-flex__item--column l-inline-input-margin"
          value={value}
          onChange={({ target }) => handleChange(target.value)}
        >
          {!value && (
            <option disabled selected>
              {' '}
              -- select an option --{' '}
            </option>
          )}

          {availableResponses.map((response) => {
            return (
              <option key={response.id} value={response.id}>
                {response.label}
              </option>
            );
          })}
        </select>{' '}
        <span className="l-flex__item--row" dangerouslySetInnerHTML={{ __html: questionFragments[1] }} />
      </label>
    </>
  );
}

function StandardInput({ questionId, question, availableResponses, handleChange, value, isSummary }) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column"
        dangerouslySetInnerHTML={{ __html: question }}
        htmlFor={`ek-question-${questionId}`}
      />
      <select
        id={`ek-question-${questionId}`}
        disabled={isSummary}
        className="c-input h-border h-border--color-grey-700 c-input--circle-select l-flex__item--column"
        value={value}
        onChange={({ target }) => handleChange(target.value)}
      >
        {availableResponses.map((response) => {
          return (
            <option key={response.id} value={response.id}>
              {response.label}
            </option>
          );
        })}
      </select>
    </>
  );
}

export function DropDownListInput({
  id,
  question,
  configuration: {
    responseSet: { availableResponses },
  },
  answers,
  isSummary,
}) {
  const [value, setValue] = useState(answers?.value ?? null);
  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerDropDownInput, { onCompleted: () => setIsLoading({ isLoading: false }) });
  const setAnswer = useSetQuestionAnswer();
  function handleChange(newSelectedId) {
    setValue(newSelectedId);

    const { scoreValue } = availableResponses.find((response) => Number(response.id) === Number(newSelectedId));
    setAnswer(id, Number(scoreValue));
    setIsLoading({ isLoading: true });
    sendAnswer({
      variables: {
        value: newSelectedId,
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }

  const hasPlaceholder = question.includes('{{Question}}');
  const questionFragments = question.split('{{Question}}');
  const Input = hasPlaceholder ? InlineInput : StandardInput;

  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      <Input
        questionId={id}
        question={question}
        isSummary={isSummary}
        questionFragments={questionFragments}
        availableResponses={availableResponses}
        handleChange={handleChange}
        value={value}
      />
    </div>
  );
}
