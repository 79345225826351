export function VideoSnippet({ asset: { presignedUrl } }) {
  return (
    <video
      className="l-flex__item--column"
      preload="metadata"
      controls
      // Time code is a hack to force iOS to load the first frame, better informing the user of a video existance
      src={`${presignedUrl}#t=0.001`}
    >
      <track kind="captions" srcLang="en" />
    </video>
  );
}
