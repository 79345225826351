import React from 'react';

import { RadioGroup } from 'components/Shared/Inputs/RadioGroup';

export interface DragItemMobileControlsProps {
  handleChoose: (value, id) => void;
  id: string;
  matchOptions: Record<string, any>[];
  selectedOption?: number;
  showHide: (e) => void;
}

function DragItemMobileControls({ handleChoose, showHide, id, matchOptions, selectedOption }: DragItemMobileControlsProps) {
  return (
    <>
      <div className="ek-drag-item__radio c-drag-item-mobile__radio-group">
        <RadioGroup
          label="Select option"
          isLabelHidden
          name={`new_select_option_${id}`}
          onChange={(value, itemId) => handleChoose(value, Number(itemId.split('_').pop()))}
          items={matchOptions}
          selected={selectedOption}
        />
      </div>
      <button className="ek-drag-item__cover-button" onClick={(e) => showHide(e)} type="button">
        <span className="h-sr-only">Click to display options</span>
      </button>
    </>
  );
}
export { DragItemMobileControls };
