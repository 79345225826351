import React from 'react';

export interface QuoteProps {
  author: string;
  quote: string;
}

function Quote({ quote, author }: QuoteProps) {
  const authorParts = author.split('(');
  const authorName = authorParts[0];
  const authorYear = authorParts[1].substring(0, authorParts[1].indexOf(')'));

  return (
    <div className="c-quote l-full-width l-flex l-flex--column">
      <p className="c-quote__text">&ldquo;{quote}&rdquo;</p>
      {author && (
        <p className="c-quote__author">
          <span className="h-sr-only">Quote given by</span>
          <span className="h-font-weight--700" aria-hidden="true">
            - {authorName}
          </span>
          <div className="h-with-text-align-right h-font-size--small">
            <span>({authorYear})</span>
          </div>
        </p>
      )}
    </div>
  );
}

export { Quote };
