import React from 'react';

import { SortOptionListItem } from 'components/SortItems/SortOptionListItem';

export interface SortOptionListProps {
  handleDragStart: (item: { id: number; position: number }) => void;
  handleDragover: (e) => void;
  handleDrop: (e, position: number) => void;
  matchItems: Record<string, any>[];
  onChange?: (value: number, id: number, type: string) => void;
  onClick: (oldPosition: number, newPosition: number) => void;
  options: Record<string, any>[];
}

function SortOptionList({ options, matchItems, handleDragStart, handleDragover, handleDrop, onClick, onChange }: SortOptionListProps) {
  const getSelected = (item) => {
    const selected = options.find((match) => match.questionResponseMatchId === item.id);
    return selected ? selected.questionAnswerMatchId : null;
  };
  return (
    <ol className="ek-option-list">
      {options.map((item, inc) => (
        <SortOptionListItem
          position={inc}
          key={`option_${item.id}`}
          id={item.id}
          label={item.label}
          matchItems={matchItems}
          handleDragStart={handleDragStart}
          handleDragover={handleDragover}
          handleDrop={handleDrop}
          selected={getSelected(item)}
          onClick={onClick}
          onChange={onChange}
        />
      ))}
    </ol>
  );
}

export { SortOptionList };
