import { useState } from 'react';

import { gql } from '@apollo/client';
import cx from 'classnames';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

import { useSetQuestionAnswer } from 'components/ActivityContent/ActivityContent';

const AnswerRadioInput = gql`
  mutation AnswerRadioInput($value: Int!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerRadio: {
          questionResponseRadioId: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;
function ImageRadioResponse({ id, label, asset, value, questionId, isSelected, onChange }) {
  return (
    <label htmlFor={`ek-question-${questionId}-radio-${id}`} className="l-flex__item--row l-flex l-flex--column">
      <span
        className={cx('l-position--relative h-border', {
          'h-border--color-brand-secondary': isSelected,
        })}
      >
        <img style={{ width: '11rem', height: 'auto' }} src={asset.presignedUrl} alt={asset.alt || label || ''} />
        <input
          style={{ position: 'absolute', top: '1rem', right: '1rem' }}
          name={questionId}
          id={`ek-question-${questionId}-radio-${id}`}
          type="radio"
          value={value}
          className="c-input"
          checked={isSelected}
          onChange={() => onChange(id)}
        />
      </span>
      <span className="l-full-width h-background--color-brand-secondary h-with-small-padding c-typography--bold">{label}</span>
    </label>
  );
}

function TextRadioResponse({ id, label, value, questionId, index, isSelected, onChange }) {
  return (
    <label className={cx('l-flex', 'l-flex--align-center', 'l-flex__item--column')} htmlFor={`ek-question-${questionId}-radio-${id}`}>
      <span
        className={cx('h-circled', 'l-flex', 'l-flex--center', {
          'h-radio-selected': isSelected,
          'h-radio-unselected': !isSelected,
        })}
      >
        {String.fromCharCode(index + 65).toLocaleLowerCase()}
      </span>
      {label}
      <input
        name={questionId}
        id={`ek-question-${questionId}-radio-${id}`}
        type="radio"
        value={value}
        checked={isSelected}
        className="h-sr-only"
        onChange={() => onChange(id)}
      />
    </label>
  );
}

export function RadioInput({
  id,
  question,
  configuration: {
    responseSet: { availableResponses = [] },
  },
  answers,
  isSummary,
}) {
  const hasImage = availableResponses.some((response) => response.asset);
  const RadioResponse = hasImage ? ImageRadioResponse : TextRadioResponse;
  const [selectedId, setSelectedId] = useState(answers?.value ?? '');

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerRadioInput, {
    onCompleted: () => setIsLoading({ isLoading: false }),
  });
  const setAnswer = useSetQuestionAnswer();

  function handleChange(newSelectedId) {
    setSelectedId(newSelectedId);

    const { scoreValue } = availableResponses.find((response) => response.id === newSelectedId);
    setAnswer(id, Number(scoreValue));
    setIsLoading({ isLoading: true });
    sendAnswer({
      variables: {
        value: newSelectedId,
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }

  return (
    <fieldset id={id} className="l-flex__item--column l-flex l-flex--column">
      <legend
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column"
        dangerouslySetInnerHTML={{ __html: question }}
      />
      <div
        className={cx('l-flex', 'l-flex__item--column', {
          'l-flex--column': !hasImage,
        })}
      >
        {availableResponses.map((response, index) => {
          if (isSummary && selectedId !== response.id) return null;
          return (
            <RadioResponse
              key={response.id}
              questionId={id}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...response}
              index={index}
              onChange={handleChange}
              isSelected={selectedId === response.id}
            />
          );
        })}
      </div>
    </fieldset>
  );
}
