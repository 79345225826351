import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { Client } from 'graphql/client';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useMutation } from 'utils/hooks/useMutation';

const CompleteContentPageTrackingMutation = gql`
  mutation CompleteContentPageTrackingMutation($pageTrackingId: Int!) {
    userFlowStepTrack {
      endContentPageCompletion(trackingId: $pageTrackingId, status: COMPLETED) {
        id
      }
    }
  }
`;

const CompleteUserFlowStepTrackingMutation = gql`
  mutation CompleteUserFlowStepTrackingMutation($stepTrackingId: Int!) {
    userFlowStepTrack {
      endUserFlowStep(trackingId: $stepTrackingId, status: COMPLETED) {
        id
      }
    }
  }
`;

const GetStepByUrl = gql`
  query GetStepByUrl($url: String!, $userFlowUrl: String!) {
    userFlowStep {
      getStepByUrl(projectId: "${process.env.REACT_APP_PROJECT_ID}" url: $url, userFlowUrl: $userFlowUrl) {
        id
      }
    }
  }
`;

const GetPageIdByUrl = gql`
  query GetPageIdByUrl($pageUrl: String!) {
    contentPage {
      getContentPagePerName(contentPageUrl: $pageUrl, projectId: "${process.env.REACT_APP_PROJECT_ID}") {
        id
      }
    }
  }
`;

const GetLatestStepTrackingId = gql`
  query GetLatestStepTrackingId($userFlowStepId: Int!) {
    userFlowStepTrack {
      latestTracking(userFlowStepId: $userFlowStepId) {
        stepTrackingId: id
        completedLongDate
        isCompleted
      }
    }
  }
`;

const StartStepMutation = gql`
  mutation StartStepMutation($userFlowStepId: Int!) {
    userFlowStepTrack {
      startUserFlowStep(stepId: $userFlowStepId) {
        stepTrackingId: id
        isCompleted
      }
    }
  }
`;

const StartPageMutation = gql`
  mutation StartPageMutation($stepTrackingId: Int!, $pageId: Int!) {
    userFlowStepTrack {
      startContentPageCompletion(trackingId: $stepTrackingId, pageId: $pageId) {
        pageTrackingId: id
      }
    }
  }
`;

function useTracking() {
  const { pageUrl, stepUrl, flowId, moduleUrl } = useRouter().query;

  const client = useRef(Client('ekardo'));

  const [isReviewMode, setIsReviewMode] = useState<boolean>();
  const [pageId, setPageId] = useState<number>();
  const [pageTrackingId, setPageTrackingId] = useState<number>();
  const [stepId, setStepId] = useState<number>();
  const [stepTrackingId, setStepTrackingId] = useState<number>();
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>(null);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState<boolean>(false);

  const [callCompleteContentPageTracking] = useMutation(CompleteContentPageTrackingMutation, {
    client: client.current,
    onCompleted() {
      setPageId(null);
      setPageTrackingId(null);
    },
  });

  const [callCompleteUserFlowStepTrackingMutation] = useMutation(CompleteUserFlowStepTrackingMutation, {
    client: client.current,
  });

  const [startPageTrackingQuery] = useMutation(StartPageMutation, {
    client: client.current,
    onCompleted(data) {
      setPageTrackingId(data.userFlowStepTrack.startContentPageCompletion.pageTrackingId);
    },
  });

  const [startStepTracking] = useMutation(StartStepMutation, {
    client: client.current,
    onCompleted(data) {
      const {
        userFlowStepTrack: {
          startUserFlowStep: { stepTrackingId, isCompleted },
        },
      } = data;
      setStepTrackingId(data.userFlowStepTrack.startUserFlowStep.stepTrackingId);
      setIsCompleted(isCompleted);
    },
  });

  useEffect(() => {
    if (!stepTrackingId) {
      return;
    } else {
      startPageTracking();
    }
  }, [stepTrackingId]);

  const [getLatestTracking] = useLazyQuery(GetLatestStepTrackingId, {
    client: client.current,
    onCompleted(data) {
      if (data.userFlowStepTrack.latestTracking) {
        const {
          userFlowStepTrack: {
            latestTracking: { stepTrackingId, completedLongDate, isCompleted },
          },
        } = data;

        setIsCompleted(isCompleted);
        setIsReviewMode(isCompleted);
        setStepTrackingId(stepTrackingId);
      } else {
        startStepTracking({
          variables: {
            userFlowStepId: stepId,
          },
        });
      }
    },
  });

  const [getStepData] = useLazyQuery(GetStepByUrl, {
    client: client.current,
    onCompleted(data) {
      setStepId(data.userFlowStep.getStepByUrl.id);
      getLatestTracking({
        variables: {
          userFlowStepId: data.userFlowStep.getStepByUrl.id,
        },
      });
    },
  });

  const [getPageId] = useLazyQuery(GetPageIdByUrl, {
    onCompleted(data) {
      setPageId(data.contentPage.getContentPagePerName.id);
      startPageTrackingQuery({
        variables: {
          stepTrackingId,
          pageId: data.contentPage.getContentPagePerName.id,
        },
      });
    },
  });

  function setIsLoading({ isLoading }) {
    setIsSubmitAvailable(isLoading);
  }
  function completeContentPageTracking({ onCompleted }) {
    callCompleteContentPageTracking({
      variables: {
        pageTrackingId,
      },
    }).then(() => {
      onCompleted?.();
    });
  }

  function completeUserFlowStepTracking() {
    callCompleteUserFlowStepTrackingMutation({
      variables: {
        stepTrackingId,
      },
    });
  }

  function startPageTracking() {
    getPageId({ variables: { pageUrl } });
  }

  function startTracking() {
    if (!stepId) {
      getStepData({
        variables: {
          url: stepUrl,
          userFlowUrl: moduleUrl,
        },
      });
    } else if (!stepTrackingId) {
      getLatestTracking({
        variables: {
          userFlowStepId: stepId,
        },
      });
    } else {
      startPageTracking();
    }
  }

  useEffect(() => {
    if (!(moduleUrl && pageUrl && stepUrl)) {
      return;
    }

    startTracking();
  }, [moduleUrl, pageUrl, stepUrl]);

  return {
    completeUserFlowStepTracking,
    completeContentPageTracking,
    isReviewMode,
    pageId,
    pageTrackingId,
    stepId,
    stepTrackingId,
    setIsLoading,
    isSubmitAvailable,
    isCompleted,
  };
}

export interface ITrackingContext {
  completeContentPageTracking: (input: { onCompleted?: () => void }) => void;
  completeUserFlowStepTracking: () => void;
  setIsLoading: (input: { isLoading: boolean }) => void;
  isReviewMode?: boolean;
  pageId?: number;
  pageTrackingId?: number;
  isCompleted?: boolean;
  stepId?: number;
  stepTrackingId?: number;
  isSubmitAvailable?: boolean;
}

const Context = createContext<ITrackingContext>({
  completeContentPageTracking: () => {},
  completeUserFlowStepTracking: () => {},
  setIsLoading: () => {},
  isCompleted: null,
  pageId: null,
  pageTrackingId: null,
  stepId: null,
  stepTrackingId: null,
  isSubmitAvailable: false,
});

export function TrackingProvider({ children }: { children: (context: ITrackingContext) => ReactNode }) {
  const tracking = useTracking();

  return <Context.Provider value={tracking}>{children(tracking)}</Context.Provider>;
}

export function ManualTrackingProvider({
  children,
  tracking,
}: {
  children: (context: ITrackingContext) => ReactNode;
  tracking: ITrackingContext;
}) {
  return <Context.Provider value={tracking}>{children(tracking)}</Context.Provider>;
}

export function useTrackingValues() {
  return useContext(Context);
}
