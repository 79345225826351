import React from 'react';

export interface SortItemSelectProps {
  id: number;
  items: Record<string, any>[];
  label?: string;
  onChange?: (value: number, id: number, type: string) => void;
  selected?: number;
  type: string;
}

function SortItemSelect({ id, selected, items, type, onChange, label = 'select option' }: SortItemSelectProps) {
  return (
    <>
      <label htmlFor={`id_select_${id}_${type}`} className="h-sr-only">
        {label}
      </label>
      <select
        className="ek-sort-item__select"
        id={`id_select_${id}_${type}`}
        onChange={({ target: { value } }) => onChange(Number(value), id, type)}
        value={selected}
      >
        <option value={0}>{label}</option>
        {items.map((item) => (
          <option key={item.id} value={item.id}>
            {type === 'option' ? item.text : item.label}
          </option>
        ))}
      </select>
    </>
  );
}

export { SortItemSelect };
