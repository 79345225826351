import { CalendarInput } from '../Inputs/CalendarInput';
import { CheckboxInput } from '../Inputs/CheckboxInput';
import { DropDownListInput } from '../Inputs/DropDownListInput';
import { MatchInput } from '../Inputs/MatchInput';
import { MultiResponseTextInput } from '../Inputs/MultiResponseTextInput';
import { MultiTextInput } from '../Inputs/MultiTextInput';
import { NumberInput } from '../Inputs/NumberInput';
import { RadioInput } from '../Inputs/RadioInput';
import { SingleTextInput } from '../Inputs/SingleTextInput';
import { SliderInput } from '../Inputs/SliderInput';

const Inputs = {
  SingleText: SingleTextInput,
  MultiText: MultiTextInput,
  Radio: RadioInput,
  Checkbox: CheckboxInput,
  DropDownList: DropDownListInput,
  Number: NumberInput,
  Slider: SliderInput,
  Match: MatchInput,
  Calendar: CalendarInput,
  MultiResponseText: MultiResponseTextInput,
};

export function QuestionSnippet({ answers, configuration, id, question, isSummary = false }) {
  // eslint-disable-next-line no-underscore-dangle
  const Input = Inputs[configuration.__typename] || 'div';
  return (
    <>
      <div className="h-with-y-padding ">
        <Input id={id} question={question} configuration={configuration} answers={answers ?? {}} isSummary={isSummary} />
      </div>
    </>
  );
}
