import React from 'react';

import cx from 'classnames';

export interface RadioButtonProps {
  id: string;
  isChecked: boolean;
  labelText: string;
  name: string;
  onChange?: (value: string, id: string) => void;
  value: string;
}

export function RadioButton({ id, isChecked, labelText, name, value = '', onChange, ...rest }: RadioButtonProps) {
  return (
    <p className="ek-radio-button l-flex__item--row">
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        onChange={() => onChange(value, id)}
        id={id}
        className={cx('ek-radio-button__input', 'h-sr-only', {
          'h-backgroud--color-brand': isChecked,
        })}
        type="radio"
        checked={isChecked}
        name={name}
        value={value}
      />
      <label className="ek-radio-button__label" htmlFor={id}>
        {labelText}
      </label>
    </p>
  );
}
