import { useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

const AnswerDateInput = gql`
  mutation AnswerDateInput($value: Date!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerCalendar: {
          dateAnswered: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

export function CalendarInput({ id, question, configuration, answers }) {
  const [value, setValue] = useState(answers?.value ?? configuration.defaultDate);
  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerDateInput, {
    onCompleted: () => setIsLoading({ isLoading: false }),
  });

  function onBlur() {
    setIsLoading({ isLoading: true });
    sendAnswer({
      variables: {
        value,
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }

  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand"
        dangerouslySetInnerHTML={{ __html: question }}
        htmlFor={`ek-question-${id}`}
      />
      <input
        id={`ek-question-${id}`}
        type="date"
        className="c-input h-border h-border--color-grey-700 l-flex__item--column"
        min={configuration.minDate}
        max={configuration.maxDate}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onBlur={onBlur}
      />
    </div>
  );
}
