import { Carousel } from 'components/Shared/UI/Carousel';
import { Quote } from 'components/Shared/UI/Quote';

export function CarouselSnippet({ slider: { speed, arrows, dots, autoplay, animation, sliderItems } }) {
  return (
    <Carousel
      arrows={arrows}
      dots={dots}
      autoplay={autoplay}
      speed={speed}
      animation={animation}
      items={sliderItems.map(({ id: key, author, quote }) => {
        return <Quote key={key} author={author} quote={quote} />;
      })}
    />
  );
}
