import React from 'react';

import cx from 'classnames';

export interface ProgressListProps {
  currentStepIndex: number;
  steps: string[];
}

export function ProgressList({ currentStepIndex, steps }: ProgressListProps) {
  return (
    <ol className="c-progress-list l-flex l-full-width l-flex--justify-space-between">
      {steps.map((step, index) => {
        return (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            aria-current={index === currentStepIndex ? 'step' : undefined}
            className={cx('c-progress-list__list-item', {
              'c-progress-list__list-item--complete': index < currentStepIndex,
              'c-progress-list__list-item--current': index === currentStepIndex,
            })}
          >
            {step}
            {index < currentStepIndex && <span className="h-sr-only">(completed)</span>}
          </li>
        );
      })}
    </ol>
  );
}
