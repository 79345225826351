import { useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

const AnswerSinglelineText = gql`
  mutation AnswerSingleTextInput($value: String!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerSinglelineText: {
          text: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

function InlineInput({ questionId, questionFragments, maxLength, minLength, onChange, value, onBlur, disabled }) {
  return (
    <>
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column l-flex"
        htmlFor={`ek-question-${questionId}`}
      >
        <span className="l-flex__item--row" dangerouslySetInnerHTML={{ __html: questionFragments[0] }} />{' '}
        <input
          id={`ek-question-${questionId}`}
          type="text"
          className="h-border h-border--color-grey-700 l-flex__item--row h-with-small-x-padding"
          style={{ borderWidth: '0 0 2px 0' }}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          minLength={minLength}
          onChange={onChange}
          onBlur={onBlur}
        />{' '}
        <span className="l-flex__item--row" dangerouslySetInnerHTML={{ __html: questionFragments[1] }} />
      </label>
    </>
  );
}

function StandardInput({ questionId, question, maxLength, minLength, onChange, onBlur, value, disabled }) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column"
        dangerouslySetInnerHTML={{ __html: question }}
        htmlFor={`ek-question-${questionId}`}
      />
      <input
        id={`ek-question-${questionId}`}
        type="text"
        className="c-input h-border h-border--color-grey-700 l-flex__item--column"
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
}

export function SingleTextInput({ id, question, configuration, answers, isSummary }) {
  const [value, setValue] = useState(answers?.value ?? '');

  const hasPlaceholder = question.includes('{{Question}}');
  const questionFragments = question.split('{{Question}}');
  const Input = hasPlaceholder ? InlineInput : StandardInput;

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerSinglelineText, {
    onCompleted: () => setIsLoading({ isLoading: false }),
  });

  function onBlur() {
    if (value !== '') {
      setIsLoading({ isLoading: true });
      sendAnswer({
        variables: {
          value,
          contentSnippetQuestionId: id,
          contentPageId: pageId,
          userFlowStepTrackId: stepTrackingId,
        },
      });
    }
  }

  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      <Input
        questionId={id}
        disabled={isSummary}
        question={question}
        questionFragments={questionFragments}
        maxLength={configuration.maxLength}
        minLength={configuration.minLength}
        onChange={({ target }) => setValue(target.value)}
        onBlur={onBlur}
        value={value}
      />
    </div>
  );
}
