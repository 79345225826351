/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { Accordion } from 'components/Shared/UI/Accordion';

import { CarouselSnippet } from './Snippets/CarouselSnippet';
import { CodeSnippet } from './Snippets/CodeSnippet';
import { DocumentSnippet } from './Snippets/DocumentSnippet';
import { FormSnippet } from './Snippets/FormSnippet';
import { ImageSnippet } from './Snippets/ImageSnippet';
import { QuestionSnippet } from './Snippets/QuestionSnippet';
import { SummarySnippet } from './Snippets/SummarySnippet';
import { TextSnippet } from './Snippets/TextSnippet';
import { VideoSnippet } from './Snippets/VideoSnippet';

const Components = {
  ContentSnippetSliderType: CarouselSnippet,
  ContentSnippetVideoType: VideoSnippet,
  ContentSnippetTextType: TextSnippet,
  ContentSnippetFormType: FormSnippet,
  ContentSnippetQuestionType: QuestionSnippet,
  ContentSnippetImageType: ImageSnippet,
  ContentSnippetCodeType: CodeSnippet,
  ContentSnippetSummaryType: SummarySnippet,
  ContentSnippetDocumentType: DocumentSnippet,
};

const Snippet = ({ __typename, contentEvent, id, ...props }) => {
  // eslint-disable-next-line no-underscore-dangle
  const Component = Components[__typename] || 'div';
  return (
    <div key={`snippet-item-${id}`} className="l-flex__item--column">
      {(() => {
        if (contentEvent && contentEvent.event === 'REVEAL') {
          return (
            <Accordion
              isOrphan
              title={contentEvent.eventTitle}
              contentClassName="h-border h-border--color-brand"
              titleClassName="c-accordion__title"
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...props} />
            </Accordion>
          );
        }

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component id={id} key={`component-${id}`} {...props} />;
      })()}
    </div>
  );
};

export { Snippet };
