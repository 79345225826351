/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

import { Button } from 'components/Shared/Inputs/Button';

const AnswerMultiResponseInput = gql`
  mutation AnswerMultiResponseInput(
    $contentPageId: Int!
    $contentSnippetQuestionId: Int!
    $responses: [QuestionAnswerMultiResponseTextItemInputType]!
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setAnswer(
        answerMultiResponseText: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          responses: $responses
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

export function MultiResponseTextInput(props) {
  const {
    answers,
    id,
    isSummary,
    configuration: { maxResponse, minResponse },
    question,
  } = props;
  const { value: answerValues = [] } = answers || {};

  const [values, setValues] = useState<string[]>(() =>
    answerValues?.length ? answerValues.map((answerValue) => answerValue.value) : Array.from({ length: minResponse }).fill(''),
  );

  const [countLoader, setCountLoader] = useState(0);
  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerMultiResponseInput, {
    onCompleted: () => setCountLoader(countLoader - 1),
  });

  useEffect(() => {
    if (countLoader > 0) {
      setIsLoading({ isLoading: true });
    } else {
      setIsLoading({ isLoading: false });
    }
  }, [countLoader]);
  function addResponse() {
    const temp = [...values];
    temp.push('');

    setValues(temp);
    onBlur(temp);
  }

  function onBlur(overrideValues?: string[]) {
    setCountLoader(countLoader + 1);
    sendAnswer({
      variables: {
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        responses: (overrideValues || values).map((value) => ({ value })),
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }

  function removeResponse(index) {
    const temp = [...values];
    temp.splice(index, 1);

    setValues(temp);
    onBlur(temp);
  }

  const canHaveMore = values.length < maxResponse && !isSummary;

  return (
    <fieldset className="l-flex__item--column l-flex l-flex--column">
      <legend
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column"
        dangerouslySetInnerHTML={{ __html: question }}
      />

      {values.map((value, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="l-flex l-flex__item--column">
            <input
              className="c-input h-border h-border--color-grey-700 l-flex__item--column"
              disabled={isSummary}
              id={id}
              type="text"
              value={value}
              onBlur={() => onBlur()}
              onChange={({ target }) =>
                setValues((currentState) => {
                  const temp = [...currentState];
                  temp[index] = target.value;
                  return temp;
                })
              }
            />
            {index + 1 > minResponse && !isSummary && (
              <div className="c-button--remove-expand">
                <Button color="tertiary" icon="minus" onClick={() => removeResponse(index)} filled round />
              </div>
            )}
          </div>
        );
      })}
      {canHaveMore && (
        <Button className="l-flex__item--column c-button--hang" icon="plus" onClick={addResponse}>
          Add new response
        </Button>
      )}
    </fieldset>
  );
}
