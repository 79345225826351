import { useState } from 'react';

import { gql } from '@apollo/client';
import cx from 'classnames';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

import { useSetQuestionAnswer } from 'components/ActivityContent/ActivityContent';

const AnswerCheckboxInput = gql`
  mutation AnswerCheckboxInput(
    $value: [QuestionAnswerCheckboxResponseCheckboxInputType]
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setAnswer(
        answerCheckbox: {
          selectedAnswers: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;
function ImageCheckboxResponse({ id, label, asset, value, questionId, isSelected, onChange }) {
  return (
    <label htmlFor={`ek-question-${questionId}-radio-${id}`} className="l-flex__item--row l-flex l-flex--column">
      <span
        className={cx('l-position--relative h-border', {
          'h-border--color-brand-secondary': isSelected,
        })}
      >
        <img style={{ width: '10rem', height: 'auto' }} src={asset.presignedUrl} alt={asset.alt || label || ''} />
        <input
          style={{ position: 'absolute', top: '1rem', right: '1rem' }}
          name={questionId}
          id={`ek-question-${questionId}-radio-${id}`}
          type="checkbox"
          value={value}
          className="c-input"
          checked={isSelected}
          onChange={() => onChange(id)}
        />
      </span>
      <span className="l-full-width h-background--color-brand-secondary h-with-small-padding c-typography--bold">{label}</span>
    </label>
  );
}

function TextCheckboxResponse({ id, label, value, questionId, index, isSelected, onChange }) {
  return (
    <label className={cx('l-flex', 'l-flex--align-center', 'l-flex__item--column')} htmlFor={`ek-question-${questionId}-radio-${id}`}>
      <span
        className={cx('h-circled', 'l-flex', 'l-flex--center', {
          'h-background--color-brand-secondary': isSelected,
          'h-background--color-brand': !isSelected,
        })}
      >
        {String.fromCharCode(index + 65).toLocaleLowerCase()}
      </span>
      {label}
      <input
        name={questionId}
        id={`ek-question-${questionId}-radio-${id}`}
        type="checkbox"
        value={value}
        className="h-sr-only"
        checked={isSelected}
        onChange={() => onChange(id)}
      />
    </label>
  );
}

export function CheckboxInput({
  id,
  question,
  configuration: {
    responseSet: { availableResponses = [] },
  },
  configuration,
  answers,
  isSummary,
}) {
  const hasImage = availableResponses.some((response) => response.asset);
  const CheckboxResponse = hasImage ? ImageCheckboxResponse : TextCheckboxResponse;
  const [selectedIds, setSelectedIds] = useState(() => {
    const temp = {};

    availableResponses.forEach((response) => {
      temp[response.id] = (answers?.value || []).map(({ questionResponseCheckboxId }) => questionResponseCheckboxId).includes(response.id);
    });
    return temp;
  });

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerCheckboxInput, {
    onCompleted: () => setIsLoading({ isLoading: false }),
  });
  const setAnswer = useSetQuestionAnswer();

  function handleChange(newSelectedId) {
    if (Object.entries(selectedIds).filter(([, value]) => value).length <= configuration.maxCheckedResponse) {
      const temp = { ...selectedIds };
      temp[newSelectedId] = !temp[newSelectedId];

      setSelectedIds(temp);

      const selectedResponses = Object.entries(temp)
        .filter(([, value]) => value)
        .map(([key]) => {
          return availableResponses.find((response) => {
            return Number(response.id) === Number(key);
          });
        });
      const value = selectedResponses.reduce((memo, current) => Number(memo) + Number(current.scoreValue), 0);
      setAnswer(id, value);
      setIsLoading({ isLoading: true });
      sendAnswer({
        variables: {
          value: selectedResponses.map((selectedResponse) => {
            return { questionResponseCheckboxId: Number(selectedResponse.id) };
          }),
          contentSnippetQuestionId: id,
          contentPageId: pageId,
          userFlowStepTrackId: stepTrackingId,
        },
      });
    }
  }

  return (
    <fieldset id={id} className="l-flex__item--column l-flex l-flex--column">
      <legend
        className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column"
        dangerouslySetInnerHTML={{ __html: question }}
      />
      <div
        className={cx('l-flex', 'l-flex__item--column', {
          'l-flex--column': !hasImage,
        })}
      >
        {availableResponses.map((response, index) => {
          if (isSummary && !selectedIds[response.id]) return null;
          return (
            <CheckboxResponse
              key={response.id}
              questionId={id}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...response}
              index={index}
              onChange={handleChange}
              isSelected={selectedIds[response.id]}
            />
          );
        })}
      </div>
    </fieldset>
  );
}
