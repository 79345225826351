import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

import { DragAndDrop } from 'components/Shared/Inputs/DragAndDrop';
import { SortItems } from 'components/SortItems';

const AnswerMatchInput = gql`
  mutation AnswerMatchInput(
    $value: [QuestionAnswerMatchAnswerInputType]
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setAnswer(
        answerMatch: {
          answers: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

export function MatchInput({ id, question, configuration, answers, isSummary }) {
  const Input = (() => {
    switch (configuration.type) {
      case 'ROW':
        return DragAndDrop;
      case 'COLUMN':
        return SortItems;
      default:
        return 'div';
    }
  })();

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerMatchInput, {
    onCompleted: () => setIsLoading({ isLoading: false }),
  });

  function handleAnswer(newAnswers) {
    setIsLoading({ isLoading: true });
    sendAnswer({
      variables: {
        value: newAnswers.map(({ questionAnswerMatchId, questionResponseMatchId }) => {
          return {
            definedPosition: configuration.matchItems.find((matchItem) => matchItem.id === questionAnswerMatchId).sort,
            questionResponseMatchId,
          };
        }),
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }

  const selectedOptions = (answers?.value || []).map(({ definedPosition, questionResponseMatchId }) => {
    return {
      definedPosition,
      questionResponseMatchId,
      questionAnswerMatchId: configuration.matchItems.find((matchItem) => {
        return matchItem.sort === definedPosition;
      }).id,
    };
  });

  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        <span
          className="ek-question-snippet c-typography--bold c-typography--color-brand l-flex__item--column"
          dangerouslySetInnerHTML={{ __html: question }}
        />
        <Input
          matchItems={configuration.matchItems || []}
          options={configuration.responseSet.availableResponses || []}
          selectedOptions={selectedOptions}
          onDrop={handleAnswer}
          isSummary={isSummary}
        />
      </label>
    </div>
  );
}
