import { useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

const AnswerMultiTextInput = gql`
  mutation AnswerMultiTextInput($value: String!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerMultilineText: {
          text: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;
export function MultiTextInput({ id, question, configuration, answers, isSummary }) {
  const [value, setValue] = useState(answers?.value ?? '');

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerMultiTextInput, { onCompleted: () => setIsLoading({ isLoading: false }) });

  function onBlur() {
    setIsLoading({ isLoading: true });
    sendAnswer({
      variables: {
        value,
        contentSnippetQuestionId: id,
        contentPageId: pageId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  }
  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand"
        dangerouslySetInnerHTML={{ __html: question }}
        htmlFor={`ek-question-${id}`}
      />
      <textarea
        id={`ek-question-${id}`}
        disabled={isSummary}
        className="c-input h-border h-border--color-grey-700 l-flex__item--column"
        value={value}
        maxLength={configuration.maxLength}
        minLength={configuration.minLength}
        onChange={({ target }) => setValue(target.value)}
        onBlur={onBlur}
      />
    </div>
  );
}
