import React from 'react';

import useDeviceDetect from 'contexts/UseDeviceDetect/UseDeviceDetect';

import { DragItem } from 'components/Shared/Inputs/DragAndDrop/DragItem';

export interface DropListProps {
  handleChoose: (value: string, id: number) => void;
  handleDragStart: (id: string) => void;
  handleDragover: (e) => void;
  handleDrop: (e, item) => void;
  matchOptions: Record<string, any>[];
  matchedItems: Record<string, any>[];
  options: Record<string, any>[];
  showHide: (e: Event) => void;
}

export function DropList({
  matchOptions,
  matchedItems,
  options,
  handleDrop,
  handleDragover,
  handleDragStart,
  handleChoose,
  showHide,
}: DropListProps) {
  const getMatchedOptionLabel = (id) => {
    const matchedOption = options.filter((option) => option.id === id)[0];
    return matchedOption?.label;
  };

  const { isTouchDevice } = useDeviceDetect();

  return (
    <ul className="ek-drop-list">
      {matchOptions.map((item) => (
        <li
          className="ek-drop-list__item"
          key={item.id}
          onDrop={!isTouchDevice ? (e) => handleDrop(e, item) : null}
          onDragOver={!isTouchDevice ? (e) => handleDragover(e) : null}
        >
          <h3 className="ek-drop-item__title">{item.text}</h3>
          <ul className="ek-drop-container">
            {matchedItems &&
              matchedItems
                .filter((match) => getMatchedOptionLabel(match.questionResponseMatchId) && match.questionAnswerMatchId === item.id)
                .map((matchedItem) => (
                  <DragItem
                    key={`${item.id}_${matchedItem.questionResponseMatchId}`}
                    label={getMatchedOptionLabel(matchedItem.questionResponseMatchId)}
                    isMatched
                    id={matchedItem.questionResponseMatchId}
                    matchedItems={matchedItems}
                    matchOptions={matchOptions}
                    handleDragStart={handleDragStart}
                    handleChoose={handleChoose}
                    showHide={showHide}
                  />
                ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
