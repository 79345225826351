import React from 'react';

import { LayoutItemProps } from './LayoutItem.types';

/**
 * An item that lives inside a Layout component
 */
const LayoutItem: React.FC<LayoutItemProps> = ({ as = 'div', children, style = {} }) => {
  /* `as` is already typed */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = as;

  return (
    <Tag className="l-layout__item" style={style}>
      {children}
    </Tag>
  );
};

export { LayoutItem };
