/* eslint-disable no-nested-ternary */
import React from 'react';

import useDeviceDetect from 'contexts/UseDeviceDetect/UseDeviceDetect';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { Icon } from 'components/Shared/UI/Icon';
import { SortItemSelect } from 'components/SortItems/SortItemSelect';

export interface SortOptionButtonsProps {
  id: number;
  items: Record<string, any>[];
  maxPosition: number;
  onChange?: (value: number, id: number, type: string) => void;
  onClick?: (oldPosition: number, newPosition: number) => void;
  position: number;
  selected?: number;
}

function SortOptionButtons({ id, items, onChange, onClick, position, maxPosition, selected }: SortOptionButtonsProps) {
  const { isMobile } = useWindowSize();
  const { isTouchDevice } = useDeviceDetect();

  return isMobile ? (
    <SortItemSelect id={id} items={items} onChange={onChange} type="option" selected={selected} label="Select answer" />
  ) : (
    <>
      <button className="ek-sort-option__button" type="button" disabled={position === 0} onClick={() => onClick(position, position - 1)}>
        <Icon className="ek-option-item__icon ek-option-item__rotate-up" id="arrow" size="sm" color="white" />
      </button>
      <button
        className="ek-sort-option__button"
        type="button"
        disabled={position === maxPosition}
        onClick={() => onClick(position, position + 1)}
      >
        <Icon className="ek-option-item__icon" id="arrow" size="sm" color="white" />
      </button>
    </>
  );
}

export { SortOptionButtons };
