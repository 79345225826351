import React, { ReactNode, useState } from 'react';

import cx from 'classnames';

import { Button } from 'components/Shared/Inputs/Button';

export interface AccordionProps {
  children: ReactNode;
  contentClassName?: string;
  defaultOpen?: boolean;
  iconSet?: 'plus' | 'arrow';
  isOrphan?: boolean;
  onOpen?: () => void;
  title: string;
  titleClassName?: string;
}

const iconSetMap = {
  plus: {
    true: {
      icon: 'minus',
      rotation: '0',
    },
    false: {
      icon: 'plus',
      rotation: '0',
    },
  },
  arrow: {
    true: {
      icon: 'arrow',
      rotation: '180',
    },
    false: {
      icon: 'arrow',
      rotation: '0',
    },
  },
};

export function Accordion({
  title,
  children,
  defaultOpen = false,
  isOrphan = false,
  onOpen,
  titleClassName,
  contentClassName,
  iconSet = 'plus',
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const Component = isOrphan ? 'div' : 'li';
  return (
    <Component className="c-accordion">
      <Button
        aria-controls={`content-${title}`}
        aria-expanded={isOpen ? 'true' : 'false'}
        className="l-full-width l-flex--justify-start"
        onClick={() => {
          setIsOpen((val) => !val);
          onOpen?.();
        }}
        icon={iconSetMap[iconSet][String(isOpen)].icon}
        rotateIcon={iconSetMap[iconSet][String(isOpen)].rotation}
        isActive={isOpen}
      >
        <span className={titleClassName}>{title}</span>
      </Button>
      <div
        className={cx({
          'c-accordion__content--default': !isOpen,
          'l-flex': isOpen,
          'h-with-padding': isOpen,
          [contentClassName]: isOpen,
        })}
        aria-hidden={isOpen ? 'false' : 'true'}
        id={`content-${title}`}
      >
        {children}
      </div>
    </Component>
  );
}
