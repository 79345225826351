import React, { useState } from 'react';

import { DragList } from 'components/Shared/Inputs/DragAndDrop/DragList';
import { DropList } from 'components/Shared/Inputs/DragAndDrop/DropList';

export interface DragAndDropProps {
  isSummary: boolean;
  matchItems: Record<string, any>[];
  onDrop: (arg: any) => void;
  options: Record<string, any>[];
  selectedOptions: Record<string, any>[];
}

export function DragAndDrop({ matchItems, options, selectedOptions = [], isSummary, onDrop }: DragAndDropProps) {
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [matchedItems, setMatchedItems] = useState(selectedOptions);

  const chooseOption = (value: string, id: number) => {
    const temp = [...matchedItems];
    const matchIndex = matchedItems.findIndex((match) => match.questionResponseMatchId === id);
    if (matchIndex === -1) {
      temp.push({
        questionResponseMatchId: id,
        questionAnswerMatchId: Number(value),
      });
    } else {
      temp[matchIndex].questionAnswerMatchId = Number(value);
    }
    setMatchedItems(temp);
    onDrop(temp);
  };

  const responseOptions = () => {
    return options.filter((el) => {
      const isMatched = matchedItems.find((match) => match.questionResponseMatchId === el.id);
      return !isMatched;
    });
  };

  const drop = (e, item) => {
    if (isSummary) return;
    e.preventDefault();
    chooseOption(item.id, draggedItemId);
    setDraggedItemId(null);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const showRadioButtons = (e) => {
    const container = e.target.closest('.ek-drag-item');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !container.classList.contains('is-active') ? container.classList.add('is-active') : container.classList.remove('is-active');
  };

  return (
    <ol className="ek-drag-drop-container">
      {!isSummary && (
        <li>
          <DragList
            options={responseOptions()}
            matchedItems={matchedItems}
            matchOptions={matchItems}
            handleDragStart={setDraggedItemId}
            handleChoose={chooseOption}
            showHide={showRadioButtons}
          />
        </li>
      )}
      <li>
        <DropList
          matchOptions={matchItems}
          matchedItems={matchedItems}
          options={options}
          handleDrop={drop}
          handleDragover={dragOver}
          handleDragStart={setDraggedItemId}
          handleChoose={chooseOption}
          showHide={showRadioButtons}
        />
      </li>
    </ol>
  );
}
