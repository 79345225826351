import React, { ReactNode, useState, useEffect, useRef } from 'react';

import cx from 'classnames';

export interface CarouselProps {
  animation?: 'FADE' | 'LINEAR';
  arrows?: boolean;
  autoplay?: boolean;
  dots?: boolean;
  items: ReactNode[];
  speed?: number;
}

function Carousel({ autoplay = true, items, speed = 5000, arrows = true, dots = true }: CarouselProps) {
  const [currentItem, setCurrentItem] = useState(0);
  const [playCycle, setPlayCycle] = useState(autoplay);
  const timer = useRef<NodeJS.Timeout>();

  function showNextItem() {
    setCurrentItem((current) => {
      if (current >= items.length - 1) return 0;
      return current + 1;
    });
  }

  function showPrevItem() {
    setCurrentItem((current) => {
      if (current <= 0) return items.length - 1;
      return current - 1;
    });
  }

  function handleSpecificClick(index) {
    setCurrentItem(index);
  }

  function buildTimer() {
    timer.current = setInterval(() => {
      if (playCycle) {
        showNextItem();
      }
    }, speed);
  }

  function clearTimer() {
    clearInterval(timer.current);
  }

  function resetTimer() {
    if (playCycle) {
      clearTimer();
      buildTimer();
    }
  }

  useEffect(() => {
    buildTimer();
    return () => clearTimer();
    // This should only update if playCycle changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playCycle]);

  function handlePrevClick() {
    resetTimer();
    showNextItem();
  }

  function handleNextClick() {
    resetTimer();
    showPrevItem();
  }

  return (
    <div className="l-full-width">
      <div className="l-flex c-carousel l-full-width l-flex--justify-space-between l-flex--align-center">
        {arrows && (
          <>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className="c-carousel__prev-button" type="button" aria-hidden="true" onClick={handlePrevClick} />
          </>
        )}
        <ul className="h-with-x-margin c-carousel__item-list">
          {items.map((item, index) => {
            const isCurrentItem = index === currentItem;
            return (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={cx('c-carousel__item', {
                  'h-sr-only': !isCurrentItem,
                  'l-full-width': isCurrentItem,
                })}
              >
                {item}
              </li>
            );
          })}
        </ul>
        {arrows && (
          <>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className="c-carousel__next-button" type="button" aria-hidden="true" onClick={handleNextClick} />
          </>
        )}
      </div>
      {dots && (
        <div aria-hidden="true" className="l-full-width l-flex l-flex--center h-with-y-margin">
          <ul className="l-flex l-flex--center c-carousel__selector-list">
            {items.map((_item, index) => {
              const isCurrentItem = index === currentItem;
              const Element = isCurrentItem ? 'div' : 'button';
              return (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="c-carousel__selector-item"
                >
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <Element
                    className={cx('c-carousel__selector-button', {
                      'c-carousel__selector-button--current': isCurrentItem,
                    })}
                    type={isCurrentItem ? undefined : 'button'}
                    onClick={isCurrentItem ? undefined : () => handleSpecificClick(index)}
                  />
                </li>
              );
            })}
          </ul>

          {/*
            This has been commented out temporarily until client confirms
            their preference on it's inclusion
          */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            className={`h-with-x-margin c-carausel__${playCycle ? 'pause' : 'play'}-button`}
            type="button"
            onClick={() => {
              setPlayCycle((val) => {
                if (val) {
                  clearTimer();
                } else {
                  buildTimer();
                }
                return !val;
              });
            }}
          />
        </div>
      )}
    </div>
  );
}

export { Carousel };
