import { useState } from 'react';

import { gql } from '@apollo/client';
import { useTrackingValues } from 'contexts/Tracking/Context';
import { useMutation } from 'utils/hooks/useMutation';

import { useSetQuestionAnswer } from 'components/ActivityContent/ActivityContent';

const AnswerNumberInput = gql`
  mutation AnswerNumberInput($value: Int!, $contentSnippetQuestionId: Int!, $contentPageId: Int!, $userFlowStepTrackId: Int) {
    questionAnswer {
      setAnswer(
        answerNumber: {
          numberAnswered: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

export function NumberInput({ id, question, configuration, answers, isSummary }) {
  const [value, setValue] = useState(answers?.value ?? configuration.defaultValue ?? '');

  const { pageId, stepTrackingId, setIsLoading } = useTrackingValues();
  const [sendAnswer] = useMutation(AnswerNumberInput, { onCompleted: () => setIsLoading({ isLoading: false }) });
  const setAnswer = useSetQuestionAnswer();

  function onBlur() {
    if (value !== '') {
      setAnswer(id, Number(value));

      setIsLoading({ isLoading: true });
      sendAnswer({
        variables: {
          value,
          contentSnippetQuestionId: id,
          contentPageId: pageId,
          userFlowStepTrackId: stepTrackingId,
        },
      });
    }
  }

  return (
    <div className="l-flex__item--column l-flex l-flex--column">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="ek-question-snippet c-typography--bold c-typography--color-brand"
        dangerouslySetInnerHTML={{ __html: question }}
        htmlFor={`ek-question-${id}`}
      />
      <input
        id={`ek-question-${id}`}
        type="number"
        className="c-input h-border h-border--color-grey-700 l-flex__item--column"
        min={configuration.minValue}
        max={configuration.maxValue}
        disabled={isSummary}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onBlur={onBlur}
      />
    </div>
  );
}
