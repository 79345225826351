import React from 'react';

import cx from 'classnames';

import { DragItem } from 'components/Shared/Inputs/DragAndDrop/DragItem';

export interface DragListProps {
  handleChoose: (value: string, id: number) => void;
  handleDragStart: (id: string) => void;
  matchOptions: Record<string, any>[];
  matchedItems: Record<string, any>[];
  options: Record<string, any>[];
  showHide: (e: Event) => void;
}

export function DragList({ options, matchedItems, matchOptions, handleDragStart, handleChoose, showHide }: DragListProps) {
  return (
    <ul
      className={cx('ek-drag-list', {
        'h-hide': !options.length,
      })}
    >
      {options.map((item) =>
        item.label ? (
          <DragItem
            key={item.id}
            isMatched={matchedItems.findIndex((match) => match.questionResponseMatchId === item.id) >= 0}
            matchedItems={matchedItems}
            matchOptions={matchOptions}
            handleDragStart={handleDragStart}
            id={item.id}
            handleChoose={handleChoose}
            showHide={showHide}
            label={item.label}
          />
        ) : null,
      )}
    </ul>
  );
}
