/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { QuestionSnippet } from './QuestionSnippet';

export function SummarySnippet(props) {
  console.log(props);
  const {
    contentSnippetQuestion: { answers, configuration, id, question },
  } = props;
  return <QuestionSnippet answers={answers} configuration={configuration} id={id} question={question} isSummary />;
}
