import cx from 'classnames';

import { Layout } from 'components/Shared/UI/Layouts/Layout/Layout';
import { LayoutItem } from 'components/Shared/UI/Layouts/LayoutItem/LayoutItem';
import { Snippet } from 'components/Shared/Ekardo/Snippet';

export interface EkardoContentPageProps {
  className?: string;
  contentLayoutId: any;
  hideLoader?: boolean;
  index: number;
  isInsideContainer?: boolean;
  page?: any;
  pageId?: number;
  pageLookup?: string;
  pageUrl?: string;
  sectionBackgroundColor: any;
  sectionContainers: any;
  sectionId: number;
  smallLoader?: boolean;
  transparentBackground?: boolean;
  userFlowStepTrackId?: number;
  validationMessages?: Record<number, string>;
}

export function EkardoContentPage({
  sectionId,
  sectionContainers,
  contentLayoutId,
  sectionBackgroundColor,
  className,
  isInsideContainer,
  validationMessages,
  index,
  transparentBackground,
}: EkardoContentPageProps) {
  return (
    <div
      className={cx({
        'l-full-width': true,
        'h-background--color-white': !sectionBackgroundColor && !transparentBackground && index % 2 === 0,
        'h-background--color-grey-300': !sectionBackgroundColor && !transparentBackground && index % 2 === 1,
        [`${className}`]: Boolean(className),
      })}
      key={`section-${sectionId}`}
      style={{
        backgroundColor: sectionBackgroundColor,
      }}
    >
      <div
        className={cx({
          'l-container l-container--medium l-flex h-no-padding': !isInsideContainer,
        })}
      >
        <Layout as="section" key={sectionId} typeId={contentLayoutId}>
          {sectionContainers.map(({ id: containerId, snippets, backgroundColor: containerBackgroundColor }) => {
            return (
              <LayoutItem
                key={containerId}
                style={{
                  backgroundColor: containerBackgroundColor,
                }}
              >
                {snippets.map((snippet) => {
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  return <Snippet validationMessages={validationMessages} key={snippet.id} {...snippet} />;
                })}
              </LayoutItem>
            );
          })}
        </Layout>
      </div>
    </div>
  );
}
