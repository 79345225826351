import { LoadingIcon } from 'components/Spinners/LoadingIcon';

export function LoadingOverlay() {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100vw',
        zIndex: 9999,
      }}
    >
      <LoadingIcon />
    </div>
  );
}
